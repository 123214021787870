<template>
  <div
    v-if="viewQuotesAndProjectsPermission"
    class="parentContainer"
  >
    <ProjectsContainer />
    <QuotesContainer />
  </div>
  <div
    v-else
    class="noResults"
  >
    You do not have permission to view the Quotes and Projects page.
  </div>
</template>

<script>

import ProjectsContainer from '../components/dashboard/ProjectsContainerComponent'
import QuotesContainer from '../components/dashboard/QuotesContainerComponent'
import { DataEventBus } from '../events/dataEvents'

export default {
  metaInfo: {
    title: 'iQuote - Quotes And Projects'
  },
  name: 'QuotesAndProjects',
  components: {
    ProjectsContainer,
    QuotesContainer
  },
  mixins: [
  ],
  data () {
    return {
    }
  },
  computed: {
    viewQuotesAndProjectsPermission () {
      return this.$store.getters.viewQuotesAndProjectsPermission
    }
  },
  watch: {
  },
  mounted () {
    DataEventBus.$emit('still-active')
  },
  created () {
  },
  methods: {
  }
}
</script>
<style scoped>
  .parentContainer {
    margin-bottom: 40px;
  }
</style>
